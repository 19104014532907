@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
	animation: spin infinite 1s linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}